import { useContext, useEffect, useRef, useState } from "react";
import { FiArrowLeft, FiHome, FiMinus, FiPlus, FiTrash } from "react-icons/fi";
import { useNavigate, useParams } from "react-router-dom";
import { CartContext } from "../../context/CartContext";
import { ButtonComponentLite } from "../../components/button/button-component";
import { PaymentSection } from "./section/payment-section";
import { NavbarSecondaryComponent } from "../../components/navbar/navbar-component";
import { PopUpCloseSection } from "./section/popup-close-section";
import { PaymentSuccessSection } from "./section/payment-success-section";
import { ChooseLevelNumberSection } from "./section/choose-level-number-section";
import { BillSection } from "./section/bill-section";
import { EmptyComponent } from "./component/empty-component";
import { LoadingComponent } from "../../components/loading/loading-component";
import { ErrorComponent } from "../../components/error/error-component";
import { CustomerSection } from "./section/customer-section";
import { UserContext } from "../../context/UserContext";
import { NotionComponent } from "../../components/notion";
import { PrinterServices } from "src/service/PrinterServices";
import { rupiahFormat } from "src/helper/rupiahFormat";
import { ChooseTableNumberSection } from "./section/choose-table-number-section";
import { LanguageContext } from "../../context/LanguageContext";
import language from "../../language/data.json";
import audioThankYou from "./../../assets/audio/thank-you.m4a";
import { Cart, Order, ProductCart, User } from "src/types/init";
import { ProductComponent } from "./component/product-component";
import { OrderContext } from "../../context/OrderContext";

export function OrderListPage() {
  const navigate = useNavigate();
  const { typeService } = useParams();
  const printerServices = new PrinterServices();

  const { lang } = useContext(LanguageContext);
  const { cart, addToCart, cleanCart, removeFromCart, updateCart } =
    useContext(CartContext);

  const { user } = useContext(UserContext);
  const { order, editOrder } = useContext(OrderContext);

  const [refClose, setRefClose] = useState(false);
  const [refCustomer, setRefCustomer] = useState(false);
  const [refLevelNumber, setRefLevelNumber] = useState(false);
  const [refTableNumber, setRefTableNumber] = useState(false);

  const [refBill, setRefBill] = useState(false);
  const [refPayment, setRefPayment] = useState(false);
  const [refPaymentSuccess, setRefPaymentSuccess] = useState(false);

  const [levelNumber, setLevelNumber] = useState("");
  const [tableNumber, setTableNumber] = useState("");
  const [customer, setCustomer] = useState({
    name: "",
  });

  async function handlePrint({
    resOrderId,
    typePayment,
    moneyAmount,
    cart,
    order,
    user,
    customer,
  }: {
    resOrderId: string;
    typePayment: string;
    moneyAmount: number;
    cart: Cart;
    order: Order;
    user: User;
    customer: any;
  }) {
    const printerServices = new PrinterServices();

    let printing = [];

    if (user.email == "manager@bonbon.com") {
      printing = [
        "DISMORE_CUSTOMER1",
        "DISMORE_KITCHEN1",
        "DISMORE_KITCHEN1",
        "DISMORE_KITCHEN1",
      ];
    } else {
      printing = [
        "DISMORE_CUSTOMER1",
        "DISMORE_KITCHEN1",
        "DISMORE_KITCHEN1",
        "DISMORE_KITCHEN1",
      ];
    }

    const machine = user.userRole == "tenant" ? "Self Service" : "Cashier";

    const subTotal = cart.products.reduce(
      (acc, product) =>
        acc +
        (product.product.originalPrice +
          (product.product.variants?.find(
            (variant) => variant.id == product.variant
          )?.originalPrice || 0)) *
          product.qty,
      0
    );

    const total = cart.products.reduce(
      (acc, product) =>
        acc +
        (product.product.price +
          (product.product.variants?.find(
            (variant) => variant.id == product.variant
          )?.price || 0)) *
          product.qty,
      0
    );

    const time = new Date().toLocaleString("id-ID", {
      timeZone: "Asia/Jakarta",
    });

    const tempOrders = cart.products.map((product: ProductCart) => {
      return {
        id: product.product.id,
        name: product.product.name,
        amount: product.qty,
        note: product.note,
        originalPrice:
          product.product.originalPrice +
          (product.product.variants?.find(
            (variant) => variant.id == product.variant
          )?.originalPrice || 0),
        totalPrice:
          (product.product.originalPrice +
            (product.product.variants?.find(
              (variant) => variant.id == product.variant
            )?.originalPrice || 0)) *
          product.qty,
      };
    });

    const tax = total - subTotal;
    const json = {
      printing: printing,
      moneyAmount: rupiahFormat(moneyAmount),
      returnAmount:
        typePayment == "QRIS"
          ? rupiahFormat(0)
          : rupiahFormat(moneyAmount - total),
      idOrderCustomer: resOrderId,
      typePayment: typePayment,
      typeService: "Direct",
      user: order,
      orderTime: time,
      machine: machine,
      orders: tempOrders,
      subTotal: rupiahFormat(subTotal),
      discount: rupiahFormat(0),
      tax: rupiahFormat(tax),
      total: rupiahFormat(subTotal + tax),
      repeatOrderUrl: "",
    };

    const res = await printerServices.submitPrintJob({ json, id: resOrderId });
  }

  return (
    <div className="h-full relative overflow-hidden flex flex-col items-center">
      {refClose ? (
        <PopUpCloseSection
          callback={() => {
            setRefClose(false);
          }}
        />
      ) : null}

      {refCustomer ? (
        <CustomerSection
          handleCancel={() => {
            setRefCustomer(false);
          }}
          callback={async (name: string) => {
            editOrder({ ...order, name });
            setRefCustomer(false);
            setRefLevelNumber(true);
          }}
        />
      ) : null}

      {refLevelNumber ? (
        <ChooseLevelNumberSection
          callback={(levelNumber: string) => {
            editOrder({ ...order, levelNumber: levelNumber });
            setRefLevelNumber(false);
            setRefTableNumber(true);
          }}
          handleCancel={() => {
            setRefLevelNumber(false);
          }}
        />
      ) : null}

      {refTableNumber ? (
        <ChooseTableNumberSection
          callback={(tableNumber: string) => {
            const tempProducts = cart.products.filter((x) => x.buy == true);

            cart.products = tempProducts;

            const orderInput = tempProducts.map((productCart: ProductCart) => {
              return {
                product: productCart.product,
                variant: productCart.product.variants?.find(
                  (variant) => variant.id == productCart.variant
                ),
                quantity: productCart.qty,
                notes: productCart.note,
              };
            });

            editOrder({
              ...order,
              tableNumber: tableNumber,
              orderInput: orderInput,
            });

            setRefTableNumber(false);
            setRefBill(true);
          }}
          handleCancel={() => {
            setRefTableNumber(false);
          }}
        />
      ) : null}

      {refBill ? (
        <BillSection
          order={order}
          cart={cart}
          callback={() => {
            setRefBill(false);
            setRefPayment(true);
          }}
          handleCancel={() => {
            setRefBill(false);
          }}
        />
      ) : null}

      {refPayment ? (
        <PaymentSection
          order={order}
          cart={cart}
          handlePrint={handlePrint}
          action={async (orderId: string) => {
            setRefPaymentSuccess(true);

            const audio = new Audio(audioThankYou);
            audio.play();

            setTimeout(() => {
              navigate(`/rating/${orderId}`);
            }, 2000);
          }}
          handleCancel={() => {
            setRefPayment(false);
          }}
        />
      ) : null}

      {refPaymentSuccess ? <PaymentSuccessSection /> : null}

      <div className="w-11/12 flex justify-center">
        <NavbarSecondaryComponent
          title={language.orderList.navbar[lang]}
          type="dark"
          leftIcon={FiArrowLeft}
          handleLeft={() => {
            navigate(-1);
          }}
          rightIcon={FiHome}
          handleRight={() => {
            setRefClose(true);
          }}
        />
      </div>

      <div className="w-full bg-gray-200 grow flex flex-col gap-2 overflow-scroll">
        {cart.products.length == 0 ? <EmptyComponent /> : null}
        {cart.products.map((productCart: ProductCart, index) => {
          return (
            <ProductComponent
              productCart={productCart}
              handleBuy={(buy: boolean) => {
                updateCart(
                  productCart.id,
                  productCart.qty,
                  productCart.note,
                  buy
                );
              }}
              removeProduct={() => {
                removeFromCart(productCart.id);
              }}
            />
          );
        })}

        <div className="bg-slate-200 h-0.5 w-full"></div>
      </div>

      <div className="w-full bg-white flex items-center justify-between px-[75px] py-[35px]">
        <div className="">
          <p className="font-bold text-[24px]">
            {language.orderList.allItem[lang]}
          </p>
        </div>
        <div className="flex items-center gap-4">
          <p className=" text-[20px]">{language.orderList.total[lang]}</p>
          <div className="mr-3">
            <h2 className="font-extrabold text-[35px] text-main whitespace-nowrap">
              {cart.products.length == 0
                ? language.menu.empty[lang]
                : `
              ${rupiahFormat(
                cart.products.reduce((acc, item) => {
                  return (
                    acc +
                    (item.buy == true
                      ? (item.product.originalPrice +
                          (item.product.variants?.find(
                            (variant) => variant.id == item.variant
                          )?.originalPrice ?? 0)) *
                        item.qty
                      : 0)
                  );
                }, 0)
              )}
                `}
            </h2>
          </div>
          <ButtonComponentLite
            title={language.orderList.checkout[lang]}
            handle={() => {
              if (cart.products.length > 0) setRefCustomer(true);
            }}
          />
        </div>
      </div>

      <NotionComponent />
    </div>
  );
}
